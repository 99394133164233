import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.background.primary};
  box-shadow: ${props => props.isScrolled ? props.theme.colors.shadow.medium : props.theme.colors.shadow.light};
  padding: ${props => props.isScrolled ? '0.8rem 2rem' : '1.2rem 2rem'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid rgba(0,0,0,0.05);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const Logo = styled(Link)`
  font-size: 2.5rem;
  font-weight: 800;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  letter-spacing: -1px;
  transition: all 0.3s ease;
  padding: 0.5rem;
  
  &:hover {
    transform: translateY(-1px);
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-weight: 600;
  font-size: 1.05rem;
  padding: 0.5rem;
  position: relative;
  transition: all 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: ${props => props.theme.colors.secondary};
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover {
    color: ${props => props.theme.colors.secondary};
    
    &:after {
      width: 100%;
    }
  }
`;

const ContactButton = styled(Link)`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.background.primary};
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.05rem;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.secondary};
  
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.colors.shadow.medium};
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  padding: 0.5rem;
  color: ${props => props.theme.colors.primary};
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.secondary};
    transform: scale(1.1);
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: none;
  position: fixed;
  top: ${props => props.headerHeight}px;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.background.primary}f7;
  padding: 1.5rem;
  box-shadow: ${props => props.theme.colors.shadow.medium};
  transform: translateY(${props => props.isOpen ? '0' : '-100%'});
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(10px);

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    display: block;
  }
`;

const MobileNavLink = styled(NavLink)`
  display: block;
  padding: 1.2rem;
  text-align: center;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  font-size: 1.1rem;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba(25,55,109,0.05);
  }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateHeaderHeight);
    updateHeaderHeight();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <>
      <StyledHeader ref={headerRef} isScrolled={isScrolled}>
        <Nav>
          <Logo to="/">
            FBC
          </Logo>
          <NavLinks>
            <NavLink to="/about">Über uns</NavLink>
            <NavLink to="/Leistungen">Services</NavLink>
            <NavLink to="/Karriere">Karriere</NavLink>
            <ContactButton to="/contact">Kontakt</ContactButton>
          </NavLinks>
          <MobileMenuButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? '✕' : '☰'}
          </MobileMenuButton>
        </Nav>
      </StyledHeader>
      <MobileMenu isOpen={isMobileMenuOpen} headerHeight={headerHeight}>
        <MobileNavLink to="/about" onClick={() => setIsMobileMenuOpen(false)}>
          Über uns
        </MobileNavLink>
        <MobileNavLink to="/Leistungen" onClick={() => setIsMobileMenuOpen(false)}>
          Services
        </MobileNavLink>
        <MobileNavLink to="/Karriere" onClick={() => setIsMobileMenuOpen(false)}>
          Karriere
        </MobileNavLink>
        <ContactButton 
          as={Link} 
          to="/contact" 
          onClick={() => setIsMobileMenuOpen(false)}
          style={{ margin: '1rem auto', display: 'inline-block' }}
        >
          Kontakt
        </ContactButton>
      </MobileMenu>
    </>
  );
};

export default Header; 