import React from "react";
import styled from "styled-components";

const MenuItemContainer = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 15px;
  box-shadow: ${props => props.theme.colors.shadow.light};
  margin: 20px 0;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.colors.shadow.medium};
  }
`;

const ImageContainer = styled.div`
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px 15px 0 0;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin: 1rem;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  margin: 0 1rem;
  line-height: 1.6;
`;

function MenuItem({ image, name, text }) {
  return (
    <MenuItemContainer>
      <ImageContainer style={{ backgroundImage: `url(${image})` }} />
      <Title>{name}</Title>
      <Description>{text}</Description>
    </MenuItemContainer>
  );
}

export default MenuItem;
