import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text.light};
  padding: ${props => props.theme.spacing.section};
  position: relative;
`;

const FooterContent = styled.div`
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const FooterTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.background.primary};
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    width: 40px;
    height: 2px;
    background-color: ${props => props.theme.colors.secondary};
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const FooterText = styled.p`
  color: ${props => props.theme.colors.text.light};
  line-height: 1.6;
  font-size: 1.05rem;
`;

const FooterBottom = styled.div`
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactItem = styled.a`
  color: ${props => props.theme.colors.text.light};
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.05rem;
  
  &:hover {
    color: ${props => props.theme.colors.background.primary};
    transform: translateX(5px);
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      transform: translateX(0) scale(1.05);
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    justify-content: center;
  }
`;

const SocialMediaSection = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  color: ${props => props.theme.colors.text.light};
  font-size: 1.5rem;
  transition: all 0.3s ease;
  
  &:hover {
    color: ${props => props.theme.colors.background.primary};
    transform: translateY(-3px);
  }
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme.colors.text.light};
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.05rem;
  
  &:hover {
    color: ${props => props.theme.colors.background.primary};
    transform: translateX(5px);
    
    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      transform: translateX(0) scale(1.05);
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContent>
        <FooterSection>
          <FooterTitle>FB CONSULTING</FooterTitle>
          <FooterText>
            Ihr Partner für Projektmanagement und IT-Consulting!
          </FooterText>
          <SocialMediaSection>
            <SocialIcon 
              href="https://at.linkedin.com/company/fb-consulting-trade-gmbh" 
              target="_blank"
              aria-label="LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </SocialIcon>
            <SocialIcon 
              href="https://www.tiktok.com/@fb.consulting" 
              target="_blank"
              aria-label="TikTok"
            >
              <FontAwesomeIcon icon={faTiktok} />
            </SocialIcon>
            <SocialIcon 
              href="https://www.instagram.com/fbconsulting.trade/" 
              target="_blank"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </SocialIcon>
          </SocialMediaSection>
        </FooterSection>

        <FooterSection>
          <FooterTitle>LEISTUNGEN</FooterTitle>
          <FooterLink to="/Leistungen">Projektmanagement</FooterLink>
          <FooterLink to="/Leistungen">Softwaretesting</FooterLink>
          <FooterLink to="/Leistungen">Softwareentwicklung</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>KONTAKT</FooterTitle>
          <ContactInfo>
            <ContactItem href="tel:+4366488297147">
              📞 +43 664 882 97 147
            </ContactItem>
            <ContactItem href="mailto:office@fb-c.at">
              ✉️ office@fb-c.at
            </ContactItem>
            <ContactItem href="/">
              🌐 www.fb-c.at
            </ContactItem>
            <FooterLink to="/Impressum">Impressum</FooterLink>
            <FooterLink to="/DSGVO">DSGVO</FooterLink>
          </ContactInfo>
        </FooterSection>

        <FooterSection>
          <FooterTitle>ADRESSE</FooterTitle>
          <FooterText>FB Consulting & Trade GmbH</FooterText>
          <ContactItem 
            href="https://goo.gl/maps/kQiDBREzsX6PAaus6"
            target="_blank"
          >
            📍 Neubaugasse 10/3a
            <br />
            A-1070 Wien
            <br />
            Österreich
          </ContactItem>
        </FooterSection>
      </FooterContent>

      <FooterBottom>
        <p>&copy; {new Date().getFullYear()} FB Consulting & Trade GmbH. Alle Rechte vorbehalten.</p>
      </FooterBottom>
    </StyledFooter>
  );
};

export default Footer;
