import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';

const StyledCarousel = styled(Carousel)`
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
  padding: 0 2rem;
  background: ${props => props.theme.colors.background.primary};

  .slick-dots li button {
    background: ${props => props.theme.colors.primary};
    opacity: 0.3;
  }

  .slick-dots li.slick-active button {
    opacity: 1;
  }

  .slick-slide {
    padding: 1rem;
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .slick-slide.slick-active {
    opacity: 1;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -30px;
  }
`;

const SlideContent = styled.div`
  background: ${props => props.theme.colors.background.secondary};
  padding: 3.5rem 2rem;
  border-radius: 10px;
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.theme.colors.shadow.light};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.colors.shadow.medium};
  }
`;

const Achievement = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const Description = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
`;

function Gallerie() {
    const achievements = [
        {
            number: '100+',
            description: 'Erfolgreich abgeschlossene Projekte'
        },
        {
            number: '50+',
            description: 'Zufriedene Kunden aus verschiedenen Branchen'
        },
        {
            number: '15+',
            description: 'Jahre Erfahrung in der IT-Branche'
        },
        {
            number: '30+',
            description: 'Qualifizierte IT-Experten in unserem Team'
        }
    ];

    return (
        <StyledCarousel 
            autoplay 
            autoplaySpeed={4000}
            dots={true}
            effect="fade"
            easing="ease-in-out"
            pauseOnHover={true}
        >
            {achievements.map((achievement, index) => (
                <div key={index}>
                    <SlideContent>
                        <Achievement>{achievement.number}</Achievement>
                        <Description>{achievement.description}</Description>
                    </SlideContent>
                </div>
            ))}
        </StyledCarousel>
    );
}

export default Gallerie;
