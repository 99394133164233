import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons";
import Gallerie from "../components/Gallerie";

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  min-height: 600px;
  color: white;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(11, 36, 71, 0.7); // Ihre primary color mit Transparenz
  z-index: 1;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
  padding: ${props => props.theme.spacing.section};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  text-align: left;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    text-align: center;
    align-items: center;
    padding: 2rem;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  font-weight: ${props => props.theme.typography.fontWeights.bold};
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: white;
  text-align: left;
  
  span {
    display: block;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 2.5rem;
    text-align: center;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 600px;
  color: ${props => props.theme.colors.text.light};
  text-align: left;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0 auto 2rem;
    text-align: center;
  }
`;

const CTAButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  background-color: white;
  color: ${props => props.theme.colors.primary};
  padding: 1.2rem 2.8rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: ${props => props.theme.typography.fontWeights.semibold};
  font-size: 1.1rem;
  transition: all 0.3s ease;
  border: 2px solid white;
  
  &:hover {
    background-color: transparent;
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);

    svg {
      transform: translateX(3px);
    }
  }

  svg {
    transition: transform 0.3s ease;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 0 auto;
  }
`;

const ServicesSection = styled.section`
  padding: ${props => props.theme.spacing.section};
  background-color: ${props => props.theme.colors.background.secondary};
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 3rem;
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 2rem;
  }
`;

const ServicesGrid = styled.div`
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
`;

const ServiceCard = styled.div`
  background: ${props => props.theme.colors.background.primary};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${props => props.theme.colors.shadow.light};
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.colors.shadow.medium};
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const LearnMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.theme.colors.secondary};
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  
  &:hover {
    color: ${props => props.theme.colors.primary};
    gap: 0.8rem;
  }
`;

const GallerieSection = styled.section`
  padding: ${props => props.theme.spacing.section};
  background-color: ${props => props.theme.colors.background.primary};
`;

const Home = () => {
  return (
    <>
      <HeroSection>
        <VideoBackground autoPlay muted loop playsInline>
          <source src="/videos/hero-background.mp4" type="video/mp4" />
        </VideoBackground>
        <Overlay />
        <HeroContent>
          <HeroTitle>
            <span>Ihr Partner für</span>
            <span>IT-Consulting & Projektmanagement</span>
          </HeroTitle>
          <HeroSubtitle>
            Wir unterstützen Sie bei der digitalen Transformation Ihres Unternehmens 
            mit maßgeschneiderten Lösungen und professioneller Expertise.
          </HeroSubtitle>
          <CTAButton to="/contact">
            Kontaktieren Sie uns
            <FontAwesomeIcon icon={faArrowRight} />
          </CTAButton>
        </HeroContent>
      </HeroSection>

      <ServicesSection>
        <SectionTitle>Unsere Leistungen</SectionTitle>
        <ServicesGrid>
          <ServiceCard>
            <ServiceTitle>Projektmanagement</ServiceTitle>
            <ServiceDescription>
              Professionelle Planung und Durchführung Ihrer IT-Projekte mit 
              bewährten Methoden und erfahrenen Experten.
            </ServiceDescription>
            <LearnMoreLink to="/Leistungen">
              Mehr erfahren <FontAwesomeIcon icon={faArrowRight} />
            </LearnMoreLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>Softwaretesting</ServiceTitle>
            <ServiceDescription>
              Umfassende Qualitätssicherung Ihrer Softwarelösungen durch 
              systematisches Testing und Fehleranalyse.
            </ServiceDescription>
            <LearnMoreLink to="/Leistungen">
              Mehr erfahren <FontAwesomeIcon icon={faArrowRight} />
            </LearnMoreLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>Softwareentwicklung</ServiceTitle>
            <ServiceDescription>
              Entwicklung individueller Softwarelösungen nach Ihren spezifischen 
              Anforderungen mit modernsten Technologien.
            </ServiceDescription>
            <LearnMoreLink to="/Leistungen">
              Mehr erfahren <FontAwesomeIcon icon={faArrowRight} />
            </LearnMoreLink>
          </ServiceCard>
        </ServicesGrid>
      </ServicesSection>

      <GallerieSection>
        <SectionTitle>Unsere Erfolge</SectionTitle>
        <Gallerie />
      </GallerieSection>
    </>
  );
};

export default Home;

