export const theme = {
  colors: {
    primary: '#0B2447',
    secondary: '#19376D',
    text: {
      primary: '#1a1a1a',
      secondary: '#4a4a4a',
      light: 'rgba(255, 255, 255, 0.9)'
    },
    background: {
      primary: '#fff',
      secondary: '#f8f9fa',
      gradient: 'linear-gradient(135deg, #0B2447 0%, #19376D 100%)'
    },
    shadow: {
      light: '0 4px 6px rgba(0, 0, 0, 0.05)',
      medium: '0 4px 12px rgba(25,55,109,0.2)'
    }
  },
  spacing: {
    section: '6rem 2rem',
    container: '1400px'
  },
  breakpoints: {
    mobile: '768px'
  },
  typography: {
    fontFamily: "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
    fontWeights: {
      regular: 400,
      semibold: 600,
      bold: 700
    }
  }
}; 