import React from 'react';
import Navbar2 from '../../components/Navbar2';
import  "../../styles/Jobs.css";
const Jobs = () => {
    const jobListings = [
        {
            title: 'Java Softwareentwickler',
            description: 'Zur Verstärkung unseres Teams sind wir auf der Suche nach qualifizierten und kompetenten Java Softwareentwicklern (w/m/d)',
            requirements: [
                'Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige Ausbildung',
                'Mindestens 2 Jahre Berufserfahrung als Java Entwickler',
                'Kenntnisse in Java 8 EE, Springboot, Hibernate, Oracle DB, REST API, Maven, Azure DevOps, Git Repository, MySQL',
                'Entwicklungsumgebungen IntelliJ, VS Code',
                'Genaue und sorgfältige Arbeitsweise',
                'Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift'
            ],
            tasks: [
                'Design und Entwicklung von State-of-the-Art Enterprise Software in Java',
                'Mitarbeit in verschiedenen IT Projekten beim Kunden',
                'Regelmäßiger Austausch und enge Zusammenarbeit mit den anderen Projektmitgliedern'
            ],
            benefits: [
                'Gezielte Karriereplanung und ausgezeichnete Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und Trainings)',
                'Attraktive Vergütung',
                'Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer Straße',
                'Hands-on-Mentalität',
                'Mitarbeiterevents',
                'Flache Hierarchien',
                'Flexible Arbeitszeiten sowie Home-Office Möglichkeiten',
                'Free Drinks & Snacks nach deiner Wahl und weitere Benefits',
                'State-of-Art Entwicklernotebooks werden zur Verfügung gestellt',
                'Bis zu 100% Homeoffice möglich'
            ],
            contact: 'Wir freuen uns über deine Bewerbung per E-Mail an '
        },
        {
            title: 'Software Tester',
            description: 'Wir suchen einen detailorientierten Software Tester, um die Qualität unserer Anwendungen sicherzustellen.',
            requirements: [
                'Kenntnisse in Software-Testmethoden',
                'Erfahrung mit automatisierten Testwerkzeugen',
                'Hohe Aufmerksamkeit für Details',
                'Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige Ausbildung',
                'Mindestens 2 Jahre Berufserfahrung als Tester/ Testautomatisierer',
                'Erfahrung mit Azure DevOps / Testplans und agiler Projektmethodik von Vorteil',
                'Gute Kenntnisse im Umgang mit Jira und weitere Testtools sind von Vorteil',
                'Erfahrung mit Selenium Webdriver',
                'ISTQB-Zertifizierung oder TOSCA Kenntnisse von Vorteil',
                'Genaue und sorgfältige Arbeitsweise',
                'Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift'
            ],
            tasks: [
                'Testdurchführung und Testfallerstellung',
                'Erstellen von Reports',
                'Mitarbeit im gesamten Testprozess'
            ],
            benefits: [
                'Gezielte Karriereplanung und ausgezeichnete Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und Trainings)',
                'Attraktive Vergütung',
                'Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer Straße',
                'Hands-on-Mentalität',
                'Mitarbeiterevents',
                'Flache Hierarchien',
                'Flexible Arbeitszeiten sowie Home-Office Möglichkeiten',
                'Free Drinks & Snacks nach deiner Wahl und weitere Benefits',
                'State-of-Art Entwicklernotebooks werden zur Verfügung gestellt',
                'Bis zu 100% Homeoffice möglich'
            ],
            contact: 'Wir freuen uns über deine Bewerbung per E-Mail an '
        },
        {
            title: 'Junior Projektmanager',
            description: 'Zur Verstärkung unseres Teams sind wir auf der Suche nach qualifizierten und kompetenten Junior Projektmanagern. (m/w/d)',
            requirements: [
                'Abgeschlossenes Studium mit IT-Schwerpunkt oder gleichwertige Ausbildung',
                'Erste facheinschlägige Berufserfahrung ist von Vorteil',
                'Kenntnisse im IT-Anforderungsmanagement oder der Softwarearchitektur',
                'Fähigkeit komplexe Projekte zu strukturieren und zu managen',
                'Hohe Selbstständigkeit, Kommunikationsstärke und Durchsetzungsvermögen',
                'Sehr gute Deutsch- und Englischkenntnisse in Wort und Schrift'
            ],
            tasks: [
                'Planung, Steuerung und Controlling von IT-Softwareentwicklungsprojekten',
                'Führung des Projektteams, von IT-Partner/Lieferanten und der Stakeholder von der Erarbeitung der Anforderung bis zum Wartungsbetrieb',
                'Laufende Abstimmung mit den internen und externen Projektauftraggebern sowie anderen Projekten innerhalb eines Portfolios',
                'Beratung der AuftraggeberInnen über optimierte Lösung Szenarien in Abstimmung mit den Business AnalystInnen',
                'Erstellung von Projekt-Reports',
                'Definieren und umsetzen der Standards und Richtlinien für die Dokumentation'
            ],
            benefits: [
                'Gezielte Karriereplanung und ausgezeichnete Entwicklungsmöglichkeiten (bezahlte Zertifizierungen und Trainings)',
                'Attraktive Vergütung',
                'Öffentlich gut erreichbares Büro – mitten auf der Mariahilfer Straße',
                'Hands-on-Mentalität',
                'Mitarbeiterevents',
                'Flache Hierarchien',
                'Flexible Arbeitszeiten sowie Home-Office Möglichkeiten',
                'Free Drinks & Snacks nach deiner Wahl und weitere Benefits'
            ],
            contact: 'Wir freuen uns über deine Bewerbung per E-Mail an '
        }
    ];

    return (
        <>
            <Navbar2 />
            <div>
                <h1>Stellenangebote</h1>
                <div className="card-container" >
                    {jobListings.map((job, index) => (
                        <div key={index} className="card">
                            <h2>{job.title}</h2>
                            <p>{job.description}</p>
                            {job.tasks && (
                                <>
                                    <h3>Deine Aufgaben:</h3>
                                    <ul>
                                        {job.tasks.map((task, index) => (
                                            <li key={index}>{task}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <h3>Du begeisterst uns durch:</h3>
                            <ul>
                                {job.requirements.map((requirement, index) => (
                                    <li key={index}>{requirement}</li>
                                ))}
                            </ul>
                            {job.benefits && (
                                <>
                                    <h3>Was wir dir anbieten:</h3>
                                    <ul>
                                        {job.benefits.map((benefit, index) => (
                                            <li key={index}>{benefit}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {job.contact && (
                                <>
                                    <h3>Kontakt:</h3>
                                    <p>{job.contact}<a href="mailto:office@fb-c.at">office@fb-c.at</a></p>
                                    
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Jobs;
