import Card3 from "../assets/Card3.jpg";
import Card5 from "../assets/Card5.jpg";
import Card6 from "../assets/Card6.jpg";

export const MenuList = [
  {
    name: "Software Entwickler",
    image: Card3,
    text: "Im IT-Sektor gibt es eine Vielzahl an Technologien und ebenso viele Spezialisten benötigt es, um ein Softwareprojekt erfolgreich umzusetzen. Die Rolle des Softwareentwicklers ist von zentraler Bedeutung für den Erfolg eines Projektes."
  },
  {
    name: "Software Tester",
    image: Card5,
    text: "Im stark expandierenden IT-Sektor kann in den letzten Jahren ein signifikanter Anstieg der Komplexität der Softwareprodukte beobachtet werden. Durch den konsequenten Einsatz von Testressourcen können Fehler frühzeitig festgestellt werden."
  },
  {
    name: "Projektmanager",
    image: Card6,
    text: "Zu den Zuständigkeiten eines Projektmanagers oder einer Projektmanagerin zählen Planung, Organisation und Steuerung von IT-Projekten. Ebenfalls im Kompetenzbereich vertreten ist die Ressourcenplanung."
  }
];
