import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const PageContainer = styled.div`
  padding: ${props => props.theme.spacing.section};
`;

const ContentWrapper = styled.div`
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
`;

const HeroSection = styled.section`
  text-align: center;
  margin-bottom: 4rem;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.colors.text.secondary};
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
`;

const Card = styled.div`
  background: ${props => props.theme.colors.background.primary};
  padding: 2rem;
  border-radius: 10px;
  box-shadow: ${props => props.theme.colors.shadow.light};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.colors.shadow.medium};
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CardText = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  line-height: 1.6;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.text.secondary};

  svg {
    color: ${props => props.theme.colors.secondary};
  }
`;

const About = () => {
  return (
    <PageContainer>
      <ContentWrapper>
        <HeroSection>
          <PageTitle>Über FB Consulting</PageTitle>
          <Subtitle>
            Wir sind Ihr verlässlicher Partner für innovative IT-Lösungen und 
            professionelles Projektmanagement seit über 15 Jahren.
          </Subtitle>
        </HeroSection>

        <Grid>
          <Card>
            <CardTitle>Unsere Mission</CardTitle>
            <CardText>
              Wir unterstützen Unternehmen dabei, ihre digitale Transformation 
              erfolgreich zu gestalten und ihre IT-Projekte effizient umzusetzen.
            </CardText>
            <List>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Professionelle Beratung
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Maßgeschneiderte Lösungen
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Nachhaltige Ergebnisse
              </ListItem>
            </List>
          </Card>

          <Card>
            <CardTitle>Unsere Werte</CardTitle>
            <CardText>
              Unsere Arbeit basiert auf klaren Werten und dem Anspruch, 
              für unsere Kunden stets die beste Qualität zu liefern.
            </CardText>
            <List>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Qualität & Zuverlässigkeit
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Innovation & Expertise
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Partnerschaftliche Zusammenarbeit
              </ListItem>
            </List>
          </Card>

          <Card>
            <CardTitle>Unser Team</CardTitle>
            <CardText>
              Unser erfahrenes Team aus IT-Experten und Projektmanagern 
              arbeitet mit Leidenschaft an Ihrem Erfolg.
            </CardText>
            <List>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Erfahrene Spezialisten
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Kontinuierliche Weiterbildung
              </ListItem>
              <ListItem>
                <FontAwesomeIcon icon={faCheckCircle} />
                Agile Arbeitsweise
              </ListItem>
            </List>
          </Card>
        </Grid>
      </ContentWrapper>
    </PageContainer>
  );
};

export default About;
