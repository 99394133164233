import "./App.css";
import 'antd/dist/antd.min.css';
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Karriere from "./pages/Karriere";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Team from "./pages/UberUns/Team";
import WirUnterstutzen from "./pages/UberUns/WirUnterstutzen";
import Zertifizierung from "./pages/UberUns/Zertifizierung";
import Bewerber from "./pages/Karriere/Bewerber";
import Leistungen from "./pages/Services/Leistungen";
import Partner from "./pages/Services/Partner";
import Kunden from "./pages/Services/Kunden";
import Termin from "./pages/Services/Termin";
import Impressum from "./pages/Kontakt/Impressum";
import DSGVO from "./pages/Kontakt/DSGVO";
import Uber from "./pages/UberUns/Uber";
import JobVorlage from "./pages/Karriere/JobVorlage";
import SoftwareentwicklerC from "./pages/Karriere/SEC";
import SoftwareentwicklerJ from "./pages/Karriere/SEJ";
import Softwaretester from "./pages/Karriere/ST";
import Projektmanager from "./pages/Karriere/Projektmanager";
import Jobs from "./pages/Karriere/Jobs";
import Header from './components/Header';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';

// Initialisiere FontAwesome
library.add(
  faArrowRight, 
  faCheckCircle,
  faInstagram, 
  faLinkedin, 
  faTiktok
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/Karriere" exact component={Karriere} />
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/Team" exact component={Team} />
            <Route path="/WirUnterstutzen" exact component={WirUnterstutzen} />
            <Route path="/Zertifizierung" exact component={Zertifizierung} />
            <Route path="/Bewerber" exact component={Bewerber} />
            <Route path="/Leistungen" exact component={Leistungen} />
            <Route path="/Partner" exact component={Partner} />
            <Route path="/Kunden" exact component={Kunden} />
            <Route path="/Termin" exact component={Termin} />
            <Route path="/Impressum" exact component={Impressum} />
            <Route path="/DSGVO" exact component={DSGVO} />
            <Route path="/Uber" exact component={Uber} />
            <Route path="/JobVorlage" exact component={JobVorlage} />
            <Route path="/SoftwareentwicklerC" exact component={SoftwareentwicklerC} />
            <Route path="/SoftwareentwicklerJ" exact component={SoftwareentwicklerJ} />
            <Route path="/Softwaretester" exact component={Softwaretester} />
            <Route path="/Projektmanager" exact component={Projektmanager} />
            <Route path="/Jobs" exact component={Jobs} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
