import React from "react";
import styled from "styled-components";
import MenuItem from "../components/MenuItem";
import { MenuList } from "../helpers/MenuList";
import "../styles/Karriere.css";
import {
  Card,
  Image,
  Text,
  Button,
  Group,
  Spoiler,
 } from "@mantine/core";
import "aos/dist/aos.css";
import JobB2 from "../assets/jobB2.jpg";

const KarriereContainer = styled.div`
  padding: ${props => props.theme.spacing.section};
`;

const ContentWrapper = styled.div`
  max-width: ${props => props.theme.spacing.container};
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  text-align: center;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 Elemente pro Zeile
  gap: 2rem;
  padding: 1rem;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); // 2 Elemente pro Zeile auf mittleren Bildschirmen
  }
  
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr; // 1 Element pro Zeile auf mobilen Geräten
  }
`;

function Karriere() {
  return (
    <KarriereContainer>
      <ContentWrapper>
        <Title>Karriere</Title>
        <MenuGrid>
          {MenuList.map((menuItem, key) => {
            return (
              <MenuItem
                key={key}
                image={menuItem.image}
                name={menuItem.name}
                text={menuItem.text}
              />
            );
          })}
        </MenuGrid>
      </ContentWrapper>
    </KarriereContainer>
  );
}

export default Karriere;
